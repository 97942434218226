import React from "react";

function Footer() {
   return (
      <>
         <footer className="footer">
            <p>Copyright © 2024. All rights are reserved</p>
         </footer>
      </>
   );
}

export default Footer;
